import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal__header" }
const _hoisted_2 = { class: "capitalized" }
const _hoisted_3 = {
  key: 0,
  class: "modal__token"
}
const _hoisted_4 = { class: "modal__token__address" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_text = _resolveComponent("n-text")!
  const _component_n_radio = _resolveComponent("n-radio")!
  const _component_Copy_Hash = _resolveComponent("Copy-Hash")!
  const _component_n_divider = _resolveComponent("n-divider")!
  const _component_add_outline = _resolveComponent("add-outline")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_button = _resolveComponent("n-button")!
  const _component_n_card = _resolveComponent("n-card")!
  const _component_n_modal = _resolveComponent("n-modal")!

  return (_openBlock(), _createBlock(_component_n_modal, { show: _ctx.show }, {
    default: _withCtx(() => [
      _createVNode(_component_n_card, {
        closable: "",
        class: "modal",
        onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('hide')))
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _cache[2] || (_cache[2] = _createTextVNode(" Wallet Details ")),
            _createVNode(_component_n_radio, {
              checked: "",
              class: "modal__header__connected",
              size: "small"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_n_text, { type: "primary" }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("Connected")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        default: _withCtx(() => [
          _cache[5] || (_cache[5] = _createElementVNode("p", { class: "modal__label" }, "Address:", -1)),
          _createVNode(_component_Copy_Hash, { value: _ctx.walletAddress }, null, 8, ["value"]),
          _createVNode(_component_n_card, { class: "modal__tokens" }, {
            default: _withCtx(() => [
              _cache[4] || (_cache[4] = _createElementVNode("h3", null, "Token Addresses", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.representations), (network, i) => {
                return (_openBlock(), _createElementBlock("div", { key: i }, [
                  _createVNode(_component_n_divider),
                  _createElementVNode("h4", _hoisted_2, _toDisplayString(network), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.representations[network]), (repr, j) => {
                    return (_openBlock(), _createElementBlock("div", { key: j }, [
                      (_ctx.representations[network][repr] !== _ctx.zeroAddress)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createTextVNode(_toDisplayString(repr) + ": ", 1),
                            _createElementVNode("div", _hoisted_4, [
                              _createVNode(_component_Copy_Hash, {
                                class: "modal__token__copy",
                                value: _ctx.representations[network][repr],
                                text: true
                              }, null, 8, ["value"]),
                              _createVNode(_component_n_button, {
                                size: "tiny",
                                class: "modal__token__button",
                                onClick: ($event: any) => (_ctx.addToken(network, repr, _ctx.representations[network][repr]))
                              }, {
                                default: _withCtx(() => [
                                  _cache[3] || (_cache[3] = _createTextVNode(" Add ")),
                                  _createVNode(_component_n_icon, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_add_outline)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show"]))
}