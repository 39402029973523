import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Copy_Hash = _resolveComponent("Copy-Hash")!
  const _component_n_card = _resolveComponent("n-card")!

  return (_ctx.txHash && _ctx.newTransaction)
    ? (_openBlock(), _createBlock(_component_n_card, {
        key: 0,
        class: "new-transaction"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.successfulTx[0]), 1),
          _createElementVNode("a", {
            href: _ctx.supportLink,
            target: "_blank",
            class: "link"
          }, _toDisplayString(_ctx.successfulTx[1]), 9, _hoisted_1),
          _createTextVNode(_toDisplayString(_ctx.successfulTx[2]) + " ", 1),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "new-transaction__hash-text" }, "Please save this transaction hash:", -1)),
          _createVNode(_component_Copy_Hash, { value: _ctx.txHash }, null, 8, ["value"])
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_Copy_Hash, {
        key: 1,
        value: _ctx.txHash
      }, null, 8, ["value"]))
}