import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "popup-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.popupList, (popup) => {
        return (_openBlock(), _createElementBlock("li", { key: popup }, [
          _createVNode(_component_Popup, {
            title: popup.title,
            message: popup.message,
            type: popup.type,
            timerID: popup.timerID
          }, null, 8, ["title", "message", "type", "timerID"])
        ]))
      }), 128))
    ])
  ]))
}