<template>
<div class="nav__outer">
  <div class="version">
      <span>You are on <a class="version__info__link" href="https://forum.celo.org/t/optics-v2-is-live/2554">Optics v1</a>. Return funds you've previously bridged here. </span>
      <a class="version__link" href="https://www.optics.app">Go to v2</a>
  </div>
  <div class="nav">
    <div class="nav__container">
      <img height="50" width="145" alt="Bridges by Optics v2" src="../assets/OpticsLong.svg" class="nav__logo"/>

      <!-- Connect should be handled by network alert if user is on unsupported network -->
      <n-button
        v-if="!walletConnected && !networkUnsupported && metamaskInstalled"
        color="#E24084"
        @click="handleConnect"
        :disabled="disabled"
        class="capitalized"
      >
        Connect Wallet
      </n-button>
      <div v-if="walletConnected && !networkUnsupported" class="nav__connected">
        <div class="nav__connected__data">
          <n-text
            class="nav__connected__data--text capitalized"
          >
            {{ networkText }}{{ walletNetwork }}
          </n-text>

          <n-text
            class="nav__connected__data--text capitalized"
            :bordered="false"
          >
            {{ balanceText }}{{ toDecimals(tokenBalance, walletToken.decimals, 4) }} {{ walletToken.symbol }}
          </n-text>
        </div>

        <div class="nav__connected__address">
          <n-button
            type="primary"
            ghost
            round
            @click="this.showAddressModal = true"
          >
            {{ truncateAddr(walletAddress) }}
          </n-button>
        </div>

      </div>
    </div>
    <Wallet-Modal
      :show="showAddressModal"
      @hide="this.showAddressModal = false"
    />
  </div>
    <div v-if="networkUnsupported">
        <Network-Alert/>
    </div>
  </div>
</template>

<script lang="ts">
import NetworkAlert from './Alerts/NetworkAlert.vue'
import { defineComponent, computed } from 'vue'
import { NButton, NText } from 'naive-ui'
import { BigNumber } from 'ethers'
import { useStore } from '../store'
import { truncateAddr, toDecimals } from '../utils/index'
import { headerNetwork, headerBalance } from '../i18n/main.json'
import WalletModal from './WalletModal.vue'

export default defineComponent({
  components: {
    NetworkAlert,
    NButton,
    NText,
    WalletModal
  },

  data: () => ({
    disabled: false,
    balance: BigNumber.from('0'),
    networkText: headerNetwork,
    balanceText: headerBalance,
    showAddressModal: false
  }),

  setup () {
    const store = useStore()

    return {
      // access a state in computed function
      walletAddress: computed(() => store.state.wallet.address),
      walletConnected: computed(() => store.state.wallet.connected),
      walletNetwork: computed(() => store.state.wallet.network),
      walletToken: computed(() => store.state.wallet.token),
      networkUnsupported: computed(() => store.state.wallet.networkUnsupported),
      tokenBalance: computed(() => store.state.multiProvider.balance),
      store
    }
  },

  methods: {
    truncateAddr,
    toDecimals,
    // connection should only be initiated in response to direct user action
    // button should be disabled while connection request is pending
    // never initiate connection request on page load
    async handleConnect () {
      // Disable button
      this.disabled = true
      await this.store.dispatch('connectWallet')
      this.disabled = false
      await setTimeout(async () => {
        await this.getBalanceFromWallet()
      }, 1000)
    },

    async getBalanceFromWallet () {
      this.store.dispatch('getBalanceFromWallet')
    }
  },

  computed: {
    metamaskInstalled (): boolean {
      const { ethereum } = window as any
      if (!ethereum) return false
      return !ethereum.isMetamask
    }
  }
})

</script>

<style scoped>

.nav__outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 99;
}

.nav {
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--bg-dark);
  border-bottom: 0.5px solid darkgray;
  z-index: 99;
}
.nav__container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.nav__logo {
  height: 60px;
  margin: 0px 10px;
}

.nav__connected {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.nav__connected__data {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}
.nav__connected__data--text {
  display: flex;
  justify-content: space-between;
  line-height: 1.2;
}

/* mobile */
@media only screen and (max-width: 600px) {
  .nav__connected__address {
    display: none;
  }
  .nav__connected__data {
    align-items: flex-end;
    margin-right: 0;
  }
  .nav__logo {
    height: 50px;
    margin-left: 0;
  }
}

.version {
  position: relative;
  background-color: #7e2373;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 9px 12px;
  font-size: 14px;
  border-bottom: solid 1px #e24084;
  color: rgba(255, 255, 255, 0.82);
  background-color: rgba(226, 64, 132, 0.7);
}

.version__info__link {
  color: white;
}

.version__link {
  color: white;
  margin: 0 1em;
}
</style>
