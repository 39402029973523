import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "popup__bg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_alert = _resolveComponent("n-alert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_n_alert, {
      onMouseover: _ctx.retainPopup,
      class: "popup",
      title: _ctx.title,
      type: _ctx.type,
      closable: "",
      "on-close": _ctx.handleClose
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.message), 1)
      ]),
      _: 1
    }, 8, ["onMouseover", "title", "type", "on-close"])
  ]))
}