<template>
  <div class="logo-container">
    <svg class="logo" width="800" height="800" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="right" d="M611.245 275.436L666.667 400.474L611.245 525.4L400.81 577.269L510.4 400.474L400.81 224.349L611.245 275.436Z" fill="#5A94E8" fill-opacity="0.7"/>
      <path class="bottom" d="M524.325 611.245L399.287 666.667L274.361 611.245L222.492 400.809L399.287 510.4L575.412 400.809L524.325 611.245Z" fill="#E24084" fill-opacity="0.7"/>
      <path class="left" d="M188.755 524.325L133.333 399.287L188.755 274.361L399.19 222.492L289.6 399.287L399.19 575.412L188.755 524.325Z" fill="#A63099" fill-opacity="0.7"/>
      <path class="top" d="M273.579 188.755L398.617 133.333L523.543 188.755L575.412 399.191L398.617 289.6L222.492 399.191L273.579 188.755Z" fill="#6EC7EC" fill-opacity="0.7"/>
    </svg>
    <p v-if="metamaskInstalled" class="text">Please connect to Metamask to continue.</p>
    <p v-else class="text">Please <a href="https://metamask.io/download.html" target="_blank" class="link">install Metamask</a> to continue.
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    metamaskInstalled (): boolean {
      const { ethereum } = window as any
      if (!ethereum) return false
      return !ethereum.isMetamask
    }
  }
})
</script>

<style scoped>
/* TODO: make this logo resizeable */
.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: var(--body-height);
  margin-top: -30px;
  width: 100%;
}
.logo {
  width: 100%;
  max-width: 500px;
  max-height: 500px;
}
.text {
  max-width: 300px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  font-family: v-sans, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

/* animations */
@keyframes spin {
  80% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  } 100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes left {
  40% {
    -webkit-transform: translateX(-10%);
    transform: translateX(-10%);
  } 80% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes right {
  40% {
    -webkit-transform: translateX(10%);
    transform: translateX(10%);
  } 80% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes top {
  40% {
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
  } 80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes bottom {
  40% {
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
  } 80% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.left {
  animation: left 3s ease-out infinite;
}
.bottom {
  animation: bottom 3s ease-out infinite;
}
.right {
  animation: right 3s ease-out infinite;
}
.top {
  animation: top 3s ease-out infinite;
}
.logo {
  animation: spin 3s ease-out infinite;
}

/* mobile */
@media only screen and (max-width: 600px) {
  .logo-container {
    min-height: calc(100vh - 170px);
  }
}
</style>
