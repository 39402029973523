<template>
  <n-card
    v-if="txHash && newTransaction"
    class="new-transaction"
  >
    {{ successfulTx[0] }}<a :href="supportLink" target="_blank" class="link">{{ successfulTx[1] }}</a>{{ successfulTx[2] }}

    <div class="new-transaction__hash-text">Please save this transaction hash:</div>
    <Copy-Hash :value="txHash"/>
  </n-card>

  <Copy-Hash v-else :value="txHash"/>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { NCard } from 'naive-ui'

import CopyHash from './CopyHash.vue'
import { useStore } from '@/store'
import { supportLink, successfulTx, saveTxHash } from '@/i18n/main.json'

export default defineComponent({
  components: {
    NCard,
    CopyHash
  },

  props: {
    txHash: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      // copy
      supportLink,
      successfulTx,
      saveTxHash
    }
  },

  setup () {
    const store = useStore()
    const newTransaction = computed(() => store.state.transactions.newTransaction)
    return {
      store,
      newTransaction
    }
  }
})
</script>

<style scoped>
.new-transaction {
  margin-bottom: 20px;
}
.new-transaction__hash-text {
  margin-top: 30px;
  font-weight: 300;
  margin-bottom: 10px;
}
</style>
