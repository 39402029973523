<template>
  <div v-if="text" class="copy-text">
    <div class="copy-text__overflow">{{ value }}</div>
    <n-tooltip trigger="hover" placement="top">
      <template #trigger>
        <n-button
          text
          type="primary"
          class="copy__img"
          @click="handleCopy()"
          @mouseleave="reset"
        >
          <n-icon size="medium">
            <copy-outline/>
          </n-icon>
        </n-button>
      </template>
      <span>{{ copyText }}</span>
    </n-tooltip>
  </div>

  <div v-else class="copy">
    <div class="copy__container">
      {{ value }}
    </div>

    <n-tooltip trigger="hover" placement="top">
      <template #trigger>
        <n-button
          text
          color="#E24084"
          class="copy__img"
          @click="handleCopy()"
          @mouseleave="reset"
        >
          <n-icon>
            <copy-outline/>
          </n-icon>
        </n-button>
      </template>
      <span>{{ copyText }}</span>
    </n-tooltip>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { NTooltip, NIcon, NButton } from 'naive-ui'
import { CopyOutline } from '@vicons/ionicons5'

export default defineComponent({
  props: {
    value: {
      type: String,
      required: true
    },
    text: {
      type: Boolean,
      default: false
    }
  },

  components: {
    NTooltip,
    NIcon,
    NButton,
    CopyOutline
  },

  data () {
    return {
      copyText: 'copy'
    }
  },

  methods: {
    handleCopy () {
      const self = this
      navigator.clipboard.writeText(this.value!).then(function () {
        console.log('Async: Copying to clipboard was successful!')
        self.copyText = 'copied'
      }, function (err) {
        console.error('Async: Could not copy text: ', err)
      })
    },

    reset () {
      setTimeout(() => {
        this.copyText = 'copy'
      }, 500)
    }
  }
})

</script>

<style scoped>
.copy {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #E24084;
  color: #E24084;
  padding: 10px;
  border-radius: 3px;
}
.copy__container {
  overflow: hidden;
}
.copy:hover {
  background-color: rgba(226, 64, 132, 0.1);
}
.copy__img {
  height: 18px;
  margin-left: 5px;
  font-size: 24px !important;
}

.copy-text {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}
.copy-text__overflow {
  overflow: hidden;
}
</style>
