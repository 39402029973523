import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Asset_Logo_Icon = _resolveComponent("Asset-Logo-Icon")!
  const _component_n_input_number = _resolveComponent("n-input-number")!
  const _component_n_form_item = _resolveComponent("n-form-item")!

  return (_openBlock(), _createBlock(_component_n_form_item, {
    label: `Available Balance: ${_ctx.walletBalancePretty} ${_ctx.walletToken.symbol}`,
    rule: _ctx.rule
  }, {
    default: _withCtx(() => [
      _createVNode(_component_n_input_number, {
        class: "sendAmount",
        value: _ctx.sendAmt,
        "onUpdate:value": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sendAmt) = $event)),
          _ctx.handleSendAmountChange
        ],
        placeholder: _ctx.fromAmountDefault,
        step: 0.01,
        min: "0",
        max: _ctx.walletBalancePretty,
        disabled: _ctx.sending
      }, {
        prefix: _withCtx(() => [
          _createVNode(_component_Asset_Logo_Icon, {
            class: "sendAmount__icon",
            asset: _ctx.walletToken.name,
            assetIcon: _ctx.walletToken.icon
          }, null, 8, ["asset", "assetIcon"])
        ]),
        _: 1
      }, 8, ["value", "placeholder", "max", "disabled", "onUpdate:value"])
    ]),
    _: 1
  }, 8, ["label", "rule"]))
}