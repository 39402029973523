import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/social-media/github.svg'
import _imports_1 from '../assets/social-media/medium.svg'


const _hoisted_1 = { class: "footer" }
const _hoisted_2 = { class: "footer__container" }
const _hoisted_3 = { class: "footer__icons" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "footer__links" }
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", {
          href: _ctx.githubLink,
          target: "_blank",
          class: "footer__icon"
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("img", { src: _imports_0 }, null, -1)
        ]), 8, _hoisted_4),
        _createElementVNode("a", {
          href: _ctx.mediumLink,
          target: "_blank",
          class: "footer__icon"
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("img", { src: _imports_1 }, null, -1)
        ]), 8, _hoisted_5)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _cache[2] || (_cache[2] = _createElementVNode("a", {
          class: "footer__link",
          href: "https://storage.googleapis.com/optics-audits/FTI%20Smart%20Contracts%20Assessment%20Report_Optics-Core_20211008_v2.pdf",
          target: "_blank"
        }, "Audit", -1)),
        _createElementVNode("a", {
          class: "footer__link",
          href: _ctx.docsLink,
          target: "_blank"
        }, "Docs", 8, _hoisted_7),
        _createElementVNode("a", {
          class: "footer__link",
          href: _ctx.supportLink,
          target: "_blank"
        }, "Support", 8, _hoisted_8)
      ])
    ])
  ]))
}