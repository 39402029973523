import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loading"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_text = _resolveComponent("n-text")!
  const _component_n_spin = _resolveComponent("n-spin")!
  const _component_refresh_outline = _resolveComponent("refresh-outline")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_button = _resolveComponent("n-button")!

  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_n_text, {
          type: "primary",
          class: "loading__text"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.value), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_n_spin, {
          size: "small",
          class: "loading__spin"
        })
      ]))
    : (_openBlock(), _createBlock(_component_n_button, {
        key: 1,
        text: "",
        class: "refresh",
        onClick: _ctx.refresh
      }, {
        default: _withCtx(() => [
          _cache[0] || (_cache[0] = _createElementVNode("p", { class: "refresh__text" }, "Refresh", -1)),
          _createVNode(_component_n_icon, null, {
            default: _withCtx(() => [
              _createVNode(_component_refresh_outline, {
                color: "#e24084",
                class: "refresh__icon"
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onClick"]))
}