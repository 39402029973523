<template>
  <div class="popup__bg">
    <n-alert @mouseover="retainPopup" class="popup" :title="title" :type="type" closable :on-close="handleClose">
      {{ message }}
    </n-alert>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { NAlert } from 'naive-ui'
import { useStore } from '../../store'

export default defineComponent({
  components: {
    NAlert
  },

  props: {
    title: {
      type: String,
      default: 'Alert'
    },
    message: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'default'
    },
    timerID: {
      type: Number
    }
  },
  data () {
    return {
      timer: this.timerID
    }
  },
  setup () {
    const store = useStore()

    return {
      store
    }
  },

  methods: {
    retainPopup () {
      this.store.dispatch('retainPopup', this.timer)
    },
    handleClose () {
      this.store.dispatch('removePopup', this.timer)
    }
  }
})
</script>

<style scoped>
.popup__bg {
  background-color: var(--bg-dark)
}
.popup {
  width: 350px;
  margin-bottom: 12px;
  margin-right: 4px;
  z-index: 99;

}
</style>
