import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { class: "manual_process" }
const _hoisted_4 = { class: "details" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "capitalized" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "capitalized" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_h3 = _resolveComponent("n-h3")!
  const _component_n_tag = _resolveComponent("n-tag")!
  const _component_n_alert = _resolveComponent("n-alert")!
  const _component_n_button = _resolveComponent("n-button")!
  const _component_Transaction_Success = _resolveComponent("Transaction-Success")!
  const _component_n_divider = _resolveComponent("n-divider")!
  const _component_n_card = _resolveComponent("n-card")!
  const _component_n_step = _resolveComponent("n-step")!
  const _component_n_steps = _resolveComponent("n-steps")!

  return (_ctx.txDetails)
    ? (_openBlock(), _createBlock(_component_n_card, {
        key: 0,
        class: "tx-details"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_n_h3, null, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Transaction Details")
              ])),
              _: 1
            }),
            (_ctx.txDetails.status === 'Processed')
              ? (_openBlock(), _createBlock(_component_n_tag, {
                  key: 0,
                  type: "success",
                  round: ""
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("Processed")
                  ])),
                  _: 1
                }))
              : (_ctx.requiresManualProcessing)
                ? (_openBlock(), _createBlock(_component_n_tag, {
                    key: 1,
                    type: "warning",
                    round: ""
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("Requires Manual Processing")
                    ])),
                    _: 1
                  }))
                : (_ctx.healthy)
                  ? (_openBlock(), _createBlock(_component_n_tag, {
                      key: 2,
                      type: "warning",
                      round: ""
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode("Processing | Healthy")
                      ])),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_n_tag, {
                      key: 3,
                      type: "error",
                      round: ""
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode("Processing | Delayed")
                      ])),
                      _: 1
                    }))
          ]),
          (_ctx.txDetails.status !== 'Processed' && !_ctx.healthy)
            ? (_openBlock(), _createBlock(_component_n_alert, {
                key: 0,
                type: "warning",
                class: "alert"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.timeTooltipError[0]), 1),
                  _createElementVNode("a", {
                    href: _ctx.supportLink,
                    target: "_blank",
                    class: "alert__link"
                  }, _toDisplayString(_ctx.timeTooltipError[1]), 9, _hoisted_2),
                  _createTextVNode(_toDisplayString(_ctx.timeTooltipError[2]), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _cache[6] || (_cache[6] = _createElementVNode("span", { class: "manual_process_text" }, [
              _createTextVNode(" Transactions "),
              _createElementVNode("strong", null, "to"),
              _createTextVNode(" Ethereum are no longer subsidized. Please complete the processing of the transfer on Ethereum yourself when the fraud window has expired (See below under \"Time to Processing\") ")
            ], -1)),
            (_ctx.readyForProcessing)
              ? (_openBlock(), _createBlock(_component_n_button, {
                  key: 0,
                  onClick: _ctx.triggerTXProcessing
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" Process Transaction ")
                  ])),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_Transaction_Success, {
            txHash: _ctx.txDetails.hash
          }, null, 8, ["txHash"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_n_card, { class: "details__table" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _cache[7] || (_cache[7] = _createElementVNode("p", { class: "label" }, "Amount:", -1)),
                  _createElementVNode("p", null, _toDisplayString(_ctx.txDetails.amount) + " " + _toDisplayString(_ctx.txDetails.token), 1)
                ]),
                _createVNode(_component_n_divider),
                _createElementVNode("div", _hoisted_6, [
                  _cache[8] || (_cache[8] = _createElementVNode("p", { class: "label" }, "From:", -1)),
                  _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.txDetails.origin), 1)
                ]),
                _createVNode(_component_n_divider),
                _createElementVNode("div", _hoisted_8, [
                  _cache[9] || (_cache[9] = _createElementVNode("p", { class: "label" }, "To:", -1)),
                  _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.txDetails.destination), 1)
                ]),
                _createVNode(_component_n_divider),
                _createElementVNode("div", _hoisted_10, [
                  _cache[11] || (_cache[11] = _createElementVNode("p", { class: "label" }, "Time Sent:", -1)),
                  (!_ctx.txDetails.timestamp)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_11, "Data not available"))
                    : (_openBlock(), _createElementBlock("p", _hoisted_12, [
                        _createTextVNode(_toDisplayString(_ctx.parseTimestamp(_ctx.txDetails.timestamp).date) + " ", 1),
                        _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
                        _createTextVNode(" " + _toDisplayString(_ctx.parseTimestamp(_ctx.txDetails.timestamp).time), 1)
                      ]))
                ]),
                _createVNode(_component_n_divider),
                _createElementVNode("div", _hoisted_13, [
                  _cache[12] || (_cache[12] = _createElementVNode("p", { class: "label" }, "Time to Processing:", -1)),
                  _createElementVNode("p", null, _toDisplayString(_ctx.confirmAt), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_n_steps, {
              current: _ctx.current,
              vertical: "",
              status: "process",
              size: "small",
              class: "details__status"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, i) => {
                  return (_openBlock(), _createBlock(_component_n_step, {
                    key: i,
                    title: step.title,
                    description: step.time
                  }, null, 8, ["title", "description"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["current"])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}