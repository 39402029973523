<template>
  <div v-if="loading" class="loading">
    <n-text type="primary" class="loading__text">{{ value }}</n-text>
    <n-spin size="small" class="loading__spin"/>
  </div>

  <n-button
    v-else
    text
    class="refresh"
    @click="refresh"
  >
    <p class="refresh__text">Refresh</p>
    <n-icon><refresh-outline color="#e24084" class="refresh__icon"/></n-icon>
  </n-button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { NText, NIcon, NSpin, NButton } from 'naive-ui'
import { RefreshOutline } from '@vicons/ionicons5'

export default defineComponent({
  props: {
    value: String,
    loading: Boolean
  },

  components: {
    NText,
    NIcon,
    NSpin,
    NButton,
    RefreshOutline
  },

  methods: {
    refresh () {
      this.$emit('refresh')
    }
  }
})

</script>

<style scoped>
.loading {
  display: flex;
  align-items: center;
  margin: 10px 5px;
  /* margin-bottom: 10px; */
}
.loading__text {
  font-size: 16px;
}
.loading__spin {
  margin-left: 10px;
}

.refresh {
  font-size: 24px;
  margin-right: 5px;
  cursor: pointer;
}
.refresh__text {
  margin-right: 5px;
  font-size: 16px;
  color: #E24084;
}
.refresh__icon {
  transform: rotateZ(45deg);
}
</style>
