import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "network-alert"
}
const _hoisted_2 = { class: "network-alert__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_button = _resolveComponent("n-button")!

  return ((_ctx.showAlert || _ctx.networkUnsupported) && _ctx.mmConnected)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("p", null, "You are not on a supported network. Supported networks: ", -1)),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getAvailableNetworks(), (network, i) => {
            return (_openBlock(), _createBlock(_component_n_button, {
              key: i,
              class: "network-alert__button",
              type: "default",
              color: "white",
              size: "small",
              round: "",
              ghost: "",
              onClick: ($event: any) => (_ctx.switchNetwork(network))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(network), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}