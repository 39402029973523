import { createElementVNode as _createElementVNode, openBlock as _openBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "disclaimer-alert__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_alert = _resolveComponent("n-alert")!

  return (_ctx.show)
    ? (_openBlock(), _createBlock(_component_n_alert, {
        key: 0,
        class: "disclaimer-alert",
        closable: "",
        "on-close": _ctx.dismiss
      }, {
        icon: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            "xmlns:xlink": "http://www.w3.org/1999/xlink",
            viewBox: "0 0 32 32"
          }, [
            _createElementVNode("path", {
              d: "M16 20a1.5 1.5 0 1 0 1.5 1.5A1.5 1.5 0 0 0 16 20z",
              fill: "currentColor"
            }),
            _createElementVNode("path", {
              d: "M15 9h2v9h-2z",
              fill: "currentColor"
            }),
            _createElementVNode("path", {
              d: "M26 28H6a2.002 2.002 0 0 1-2-2V6a2.002 2.002 0 0 1 2-2h20a2.002 2.002 0 0 1 2 2v20a2.002 2.002 0 0 1-2 2zM6 6v20h20.001L26 6z",
              fill: "currentColor"
            })
          ], -1)
        ])),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.disclaimer), 1)
        ]),
        _: 1
      }, 8, ["on-close"]))
    : _createCommentVNode("", true)
}