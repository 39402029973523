import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "logo-container" }
const _hoisted_2 = {
  key: 0,
  class: "text"
}
const _hoisted_3 = {
  key: 1,
  class: "text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createStaticVNode("<svg class=\"logo\" width=\"800\" height=\"800\" viewBox=\"0 0 800 800\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\" data-v-173c4fc8><path class=\"right\" d=\"M611.245 275.436L666.667 400.474L611.245 525.4L400.81 577.269L510.4 400.474L400.81 224.349L611.245 275.436Z\" fill=\"#5A94E8\" fill-opacity=\"0.7\" data-v-173c4fc8></path><path class=\"bottom\" d=\"M524.325 611.245L399.287 666.667L274.361 611.245L222.492 400.809L399.287 510.4L575.412 400.809L524.325 611.245Z\" fill=\"#E24084\" fill-opacity=\"0.7\" data-v-173c4fc8></path><path class=\"left\" d=\"M188.755 524.325L133.333 399.287L188.755 274.361L399.19 222.492L289.6 399.287L399.19 575.412L188.755 524.325Z\" fill=\"#A63099\" fill-opacity=\"0.7\" data-v-173c4fc8></path><path class=\"top\" d=\"M273.579 188.755L398.617 133.333L523.543 188.755L575.412 399.191L398.617 289.6L222.492 399.191L273.579 188.755Z\" fill=\"#6EC7EC\" fill-opacity=\"0.7\" data-v-173c4fc8></path></svg>", 1)),
    (_ctx.metamaskInstalled)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, "Please connect to Metamask to continue."))
      : (_openBlock(), _createElementBlock("p", _hoisted_3, _cache[0] || (_cache[0] = [
          _createTextVNode("Please "),
          _createElementVNode("a", {
            href: "https://metamask.io/download.html",
            target: "_blank",
            class: "link"
          }, "install Metamask", -1),
          _createTextVNode(" to continue. ")
        ])))
  ]))
}