import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "recipient__tooltip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_input = _resolveComponent("n-input")!
  const _component_n_button = _resolveComponent("n-button")!
  const _component_n_tooltip = _resolveComponent("n-tooltip")!
  const _component_n_input_group = _resolveComponent("n-input-group")!
  const _component_n_form_item = _resolveComponent("n-form-item")!

  return (_openBlock(), _createBlock(_component_n_form_item, {
    label: _ctx.headerDestinationAddress,
    path: "recipientAddress",
    rule: _ctx.rule
  }, {
    default: _withCtx(() => [
      _createVNode(_component_n_input_group, { class: "recipient" }, {
        default: _withCtx(() => [
          _createVNode(_component_n_input, {
            class: "recipient__input",
            value: _ctx.recipientAddress,
            placeholder: _ctx.destinationAddressDefault,
            disabled: !_ctx.enableCustomRecipient || _ctx.sending,
            "onUpdate:value": _ctx.handleRecipientAddressChange
          }, null, 8, ["value", "placeholder", "disabled", "onUpdate:value"]),
          _createVNode(_component_n_tooltip, { placement: "top" }, {
            trigger: _withCtx(() => [
              _createVNode(_component_n_button, {
                class: "recipient__button",
                type: "primary",
                ghost: "",
                disabled: _ctx.disableChangeButton || _ctx.sending,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showRecipientTooltip = !_ctx.showRecipientTooltip))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.changeButton), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createTextVNode(_toDisplayString(_ctx.disclaimerDestinationAddressChange) + " ", 1),
                _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                _createVNode(_component_n_button, {
                  class: "recipient__tooltip__button",
                  size: "tiny",
                  color: "#E24084",
                  onClick: _ctx.enableChangeAddr
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.disclaimerAcceptButton), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["label", "rule"]))
}