import { OFAC_SANCTIONS_LIST_URL, SANCTIONED_ADDRESSES } from '@celo/compliance'
import { ActionTree, MutationTree } from 'vuex'
import * as types from '../mutation-types'

async function SanctionedListAPI (): Promise<`0x${string}`[]> {
  try {
    const response = await fetch(OFAC_SANCTIONS_LIST_URL)
    if (!response.ok) {
      throw new Error(`HTTP error fetching sanctions! status: ${response.status}`)
    }
    return await response.json()
  } catch (error) {
    console.log(error)
    return SANCTIONED_ADDRESSES.slice()
  }
}

export interface SanctionedListState {
  list: `0x${string}`[];
}

const sanctionedListModule = {
  state: () => ({
    list: []
  }),

  mutations: <MutationTree<SanctionedListState>>{
    [types.SET_SANCTIONED_LIST] (state: SanctionedListState, list: `0x${string}`[]) {
      console.log('{dispatch} setting sanctioned list: ', list)
      state.list = list
    }
  },

  actions: <ActionTree<SanctionedListState, any>>{
    async fetchSanctionedList ({ commit }) {
      try {
        const list = await SanctionedListAPI()
        commit(types.SET_SANCTIONED_LIST, list)
      } catch (error) {
        console.error(error)
      }
    }
  }
}

export default sanctionedListModule
