<template>
  <div class="popup-list">
    <ul>
      <li v-for="popup in popupList" :key="popup">
        <Popup
          :title="popup.title"
          :message="popup.message"
          :type="popup.type"
          :timerID="popup.timerID"
        />
      </li>
    </ul>
  </div>
</template>

<script lang='ts'>
import { defineComponent, computed } from 'vue'
import { useStore } from '../../store'
import Popup from './Popup.vue'

export default defineComponent({
  components: {
    Popup
  },
  setup: () => {
    const store = useStore()

    return {
      popupList: computed(() => store.state.popups.list)
    }
  }
})
</script>

<style scoped>
.popup-list {
  position: fixed;
  top: 85px;
  right: 5px;
  z-index: 99;
}

ul {
  list-style-type: none;
}

</style>
