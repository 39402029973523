<template>
  <n-form-item
    :label="`Available Balance: ${walletBalancePretty} ${walletToken.symbol}`"
    :rule="rule"
  >
    <n-input-number
      class="sendAmount"
      v-model:value="sendAmt"
      :placeholder="fromAmountDefault"
      :step="0.01"
      min="0"
      :max="walletBalancePretty"
      :disabled="sending"
      @update:value="handleSendAmountChange"
    >
      <template #prefix>
        <Asset-Logo-Icon
          class="sendAmount__icon"
          :asset="walletToken.name"
          :assetIcon="walletToken.icon"
        />
      </template>
    </n-input-number>
  </n-form-item>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { NInputNumber, NFormItem } from 'naive-ui'

import { useStore } from '../../store'
import AssetLogoIcon from './AssetLogoIcon.vue'
import { toDecimals, getMinAmount } from '../../utils'
import { fromAmountDefault, fromAmountZeroError } from '../../i18n/main.json'

export default defineComponent({
  components: {
    NInputNumber,
    AssetLogoIcon,
    NFormItem
  },

  data () {
    const self = this
    return {
      sendAmt: 0,
      minAmt: 0,
      rule: {
        trigger: ['blur'],
        validator () {
          const amt = self.getSendAmt()
          const min = self.getMinAmt()
          console.log(min)
          console.log('calling sendamount validator', self)
          if (amt === 0) {
            return new Error(fromAmountZeroError)
          }
          if (amt < min) {
            const symbol = self.getSymbol()
            return new Error(`Minimum amount of $10: ${min} ${symbol}`)
          }
          return true
        }
      },

      // copy
      fromAmountDefault
    }
  },

  setup () {
    const store = useStore()
    const sendAmount = computed(() => store.state.userInput.sendAmount)
    const walletToken = computed(() => store.state.wallet.token)

    return {
      store,
      walletBalancePretty: computed(() => toDecimals(store.state.multiProvider.balance, store.state.wallet.token.decimals, 6)),
      walletToken,
      sending: computed(() => store.state.multiProvider.sending),
      sendAmount
    }
  },

  async mounted () {
    await this.getMinAmount()
  },

  methods: {
    handleSendAmountChange (amount: number) {
      this.store.dispatch('setSendAmount', amount)
    },

    async getMinAmount () {
      // return 0 if in development
      if (process.env.VUE_APP_OPTICS_ENVIRONMENT !== 'production') return 0

      try {
        const min = await getMinAmount(this.walletToken.coinGeckoId)
        this.minAmt = min
      } catch (e) {
        console.log(e)
        this.minAmt = this.walletToken.minAmt
      }
    },

    // get values for validator, annoying but necessary :(
    getSendAmt () {
      return this.sendAmt
    },
    getMinAmt () {
      return this.minAmt
    },
    getSymbol () {
      return this.walletToken.symbol
    }
  },

  watch: {
    walletToken () {
      this.getMinAmount()
    }
  }
})
</script>

<style scoped>
.sendAmount {
  width: 100%;
}
</style>
