import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "copy-text"
}
const _hoisted_2 = { class: "copy-text__overflow" }
const _hoisted_3 = {
  key: 1,
  class: "copy"
}
const _hoisted_4 = { class: "copy__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_copy_outline = _resolveComponent("copy-outline")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_button = _resolveComponent("n-button")!
  const _component_n_tooltip = _resolveComponent("n-tooltip")!

  return (_ctx.text)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.value), 1),
        _createVNode(_component_n_tooltip, {
          trigger: "hover",
          placement: "top"
        }, {
          trigger: _withCtx(() => [
            _createVNode(_component_n_button, {
              text: "",
              type: "primary",
              class: "copy__img",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleCopy())),
              onMouseleave: _ctx.reset
            }, {
              default: _withCtx(() => [
                _createVNode(_component_n_icon, { size: "medium" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_copy_outline)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onMouseleave"])
          ]),
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.copyText), 1)
          ]),
          _: 1
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.value), 1),
        _createVNode(_component_n_tooltip, {
          trigger: "hover",
          placement: "top"
        }, {
          trigger: _withCtx(() => [
            _createVNode(_component_n_button, {
              text: "",
              color: "#E24084",
              class: "copy__img",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleCopy())),
              onMouseleave: _ctx.reset
            }, {
              default: _withCtx(() => [
                _createVNode(_component_n_icon, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_copy_outline)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onMouseleave"])
          ]),
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.copyText), 1)
          ]),
          _: 1
        })
      ]))
}