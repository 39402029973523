import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "search__header" }
const _hoisted_2 = { class: "search__inputs" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_h3 = _resolveComponent("n-h3")!
  const _component_n_text = _resolveComponent("n-text")!
  const _component_Loading_Text = _resolveComponent("Loading-Text")!
  const _component_n_divider = _resolveComponent("n-divider")!
  const _component_n_select = _resolveComponent("n-select")!
  const _component_n_input = _resolveComponent("n-input")!
  const _component_search_outline = _resolveComponent("search-outline")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_n_button = _resolveComponent("n-button")!
  const _component_n_card = _resolveComponent("n-card")!
  const _component_Transaction_Details = _resolveComponent("Transaction-Details")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_n_card, { class: "search" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_n_h3, { class: "search__header__title" }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Search Transaction")
            ])),
            _: 1
          }),
          (_ctx.error)
            ? (_openBlock(), _createBlock(_component_n_text, {
                key: 0,
                type: "error"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("Error fetching transaction")
                ])),
                _: 1
              }))
            : (_ctx.fetching)
              ? (_openBlock(), _createBlock(_component_Loading_Text, {
                  key: 1,
                  value: "Fetching Transaction",
                  loading: true,
                  class: "search__header__loading"
                }))
              : _createCommentVNode("", true)
        ]),
        _createVNode(_component_n_divider),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_n_select, {
            class: "search__network capitalized",
            placeholder: "Origin Network",
            options: _ctx.networkOptions,
            value: _ctx.network,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.network) = $event))
          }, null, 8, ["options", "value"]),
          _createVNode(_component_n_input, {
            value: _ctx.hash,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.hash) = $event)),
            class: "search__hash",
            placeholder: "Transaction Hash",
            onKeyup: _withKeys(_ctx.addTx, ["enter"])
          }, null, 8, ["value", "onKeyup"]),
          _createVNode(_component_n_button, {
            text: "",
            color: "#E24084",
            class: "search__button",
            onClick: _ctx.addTx
          }, {
            default: _withCtx(() => [
              _createVNode(_component_n_icon, null, {
                default: _withCtx(() => [
                  _createVNode(_component_search_outline)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }),
    (_ctx.fetchedTx)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
          _createVNode(_component_Transaction_Details, { "tx-details": _ctx.fetchedTx }, null, 8, ["tx-details"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}