<template>
  <n-card class="search">
    <div class="search__header">
      <n-h3 class="search__header__title">Search Transaction</n-h3>
      <n-text v-if="error" type="error">Error fetching transaction</n-text>
      <Loading-Text
        v-else-if="fetching"
        value="Fetching Transaction"
        :loading="true"
        class="search__header__loading"
      />
    </div>

    <n-divider/>

    <div class="search__inputs">
      <n-select
        class="search__network capitalized"
        placeholder="Origin Network"
        :options="networkOptions"
        v-model:value="network"
      />

      <n-input
        v-model:value="hash"
        class="search__hash"
        placeholder="Transaction Hash"
        @keyup.enter="addTx"
      />

      <n-button
        text
        color="#E24084"
        class="search__button"
        @click="addTx"
      >
        <n-icon>
          <search-outline/>
        </n-icon>
      </n-button>
    </div>
  </n-card>
  <span v-if="fetchedTx">
    <Transaction-Details :tx-details="fetchedTx"/>
  </span>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue'
import { NCard, NSelect, NH3, NInput, NButton, NText, NIcon, NDivider } from 'naive-ui'
import { SearchOutline } from '@vicons/ionicons5'

import { useStore } from '@/store'
import { TXData } from '@/store/modules/transactions'
import TransactionDetails from './TransactionDetails.vue'
import LoadingText from './LoadingText.vue'
import { generateNetworkOptions } from '@/utils'

export default defineComponent({
  components: {
    NCard,
    NSelect,
    NInput,
    NButton,
    NH3,
    NText,
    NIcon,
    NDivider,
    SearchOutline,
    TransactionDetails,
    LoadingText
  },

  setup () {
    const store = useStore()

    return {
      store
    }
  },

  data () {
    return {
      showModal: ref(false),
      networkOptions: generateNetworkOptions(),
      network: this.$route.query.network || ref(null),
      hash: this.$route.query.hash || ref(null),
      error: false,
      fetchedTx: null,
      fetching: false
    }
  },

  mounted () {
    if (this.network && this.hash) {
      this.addTx()
    }
    // setInterval(() => {
    //   if (this.network && this.hash) {
    //     this.addTx()
    //   }
    // }, 60000)
  },

  methods: {
    async addTx () {
      // clear error if it was set
      if (this.error) {
        this.error = false
      }

      this.fetching = true

      const payload: TXData = {
        network: this.store.getters.resolveDomain(this.network!),
        hash: this.hash! as string,
        timestamp: undefined
      }

      try {
        const data = await this.store.getters.getTxMessage(payload)
        this.store.dispatch('addSearchTransaction', payload)
        this.fetchedTx = data
        this.error = false
      } catch (e) {
        console.log('error fetching tx')
        console.error(e)
        this.error = true
      }

      this.fetching = false
    }
  }
})

</script>

<style scoped>
.search {
  margin-bottom: 20px;
}
.search__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search__header__title {
  margin-bottom: 0;
}
.search__header__loading {
  margin: 0 5px;
}

.search__network {
  max-width: 150px;
  margin-right: 20px;
}

.search__inputs {
  display: flex;
  margin-bottom: 20px;
}

.search__button {
  margin-left: 10px;
  font-size: 24px;
}
</style>
