import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "asset-and-ognetwork" }
const _hoisted_2 = {
  key: 0,
  class: "sending-animation"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Disclaimer = _resolveComponent("Disclaimer")!
  const _component_Asset_Logo_Icon = _resolveComponent("Asset-Logo-Icon")!
  const _component_n_select = _resolveComponent("n-select")!
  const _component_Balance_And_Send_Amt = _resolveComponent("Balance-And-Send-Amt")!
  const _component_n_card = _resolveComponent("n-card")!
  const _component_Recipient_Address = _resolveComponent("Recipient-Address")!
  const _component_Logo_Wheel = _resolveComponent("Logo-Wheel")!
  const _component_Send = _resolveComponent("Send")!
  const _component_n_form = _resolveComponent("n-form")!

  return (_openBlock(), _createBlock(_component_n_form, {
    ref: "formRef",
    model: _ctx.formModel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        id: "form-inputs",
        class: _normalizeClass({ hidden: _ctx.sending })
      }, [
        _createVNode(_component_Disclaimer),
        _createVNode(_component_n_card, { title: _ctx.headerFrom }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_Asset_Logo_Icon, {
                asset: _ctx.formModel.asset,
                assetIcon: _ctx.walletToken.icon
              }, null, 8, ["asset", "assetIcon"]),
              _createVNode(_component_n_select, {
                class: "asset-and-ognetwork__select",
                placeholder: "Select Asset",
                options: _ctx.assetOptions,
                value: _ctx.formModel.asset,
                disabled: _ctx.sending,
                "onUpdate:value": _ctx.handleAssetChange
              }, null, 8, ["options", "value", "disabled", "onUpdate:value"]),
              _cache[0] || (_cache[0] = _createElementVNode("p", { class: "asset-and-ognetwork__on" }, "on", -1)),
              _createVNode(_component_n_select, {
                class: "network__select capitalized",
                placeholder: "Select Origin Network",
                options: _ctx.originNetworkOptions,
                value: _ctx.formModel.originNetwork,
                disabled: _ctx.sending,
                "onUpdate:value": _ctx.handleOriginNetworkChange
              }, null, 8, ["options", "value", "disabled", "onUpdate:value"])
            ]),
            _createVNode(_component_Balance_And_Send_Amt)
          ]),
          _: 1
        }, 8, ["title"]),
        _cache[1] || (_cache[1] = _createElementVNode("hr", { class: "divider" }, null, -1)),
        _createVNode(_component_n_card, { title: _ctx.headerTo }, {
          default: _withCtx(() => [
            _createVNode(_component_n_select, {
              class: "to-network__select",
              placeholder: _ctx.toNetworkDefault,
              value: _ctx.formModel.destinationNetwork,
              disabled: _ctx.sending,
              options: _ctx.destinationNetworkOptions,
              "onUpdate:value": _ctx.handleDestNetworkChange
            }, null, 8, ["placeholder", "value", "disabled", "options", "onUpdate:value"]),
            (_openBlock(), _createBlock(_component_Recipient_Address, {
              key: _ctx.formModel.destinationNetwork
            }))
          ]),
          _: 1
        }, 8, ["title"])
      ], 2),
      (_ctx.sending)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_Logo_Wheel)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_Send)
    ]),
    _: 1
  }, 8, ["model"]))
}