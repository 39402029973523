<template>
  <n-card class="card">
    <n-tabs :value="activeTab" size="large" animated="true" @update:value="handleNavigate">
      <n-tab-pane :name="firstTab" :tab="firstTab" />
      <!-- <n-tab-pane :name="secondTab" :tab="secondTab" /> -->
      <n-tab-pane :name="thirdTab" :tab="thirdTab" />
    </n-tabs>
    <router-view/>
  </n-card>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { NCard, NTabs, NTabPane } from 'naive-ui'

import { useStore } from '../store'
import router from '../router'
import { firstTab, secondTab, thirdTab } from '../i18n/main.json'

const routes = {
  [firstTab]: '/',
  [secondTab]: '/transactions',
  [thirdTab]: '/search-transaction'
}

export default defineComponent({
  components: {
    NCard,
    NTabs,
    NTabPane
  },

  data () {
    return {
      firstTab,
      // secondTab,
      thirdTab
    }
  },

  setup () {
    const store = useStore()

    return {
      store,
      newTransaction: computed(() => store.state.transactions.newTransaction),
      newestTransaction: computed(() => store.state.transactions.transactions[0])
    }
  },

  methods: {
    handleNavigate (name: string) {
      const path = routes[name]
      if (path === '/') {
        this.store.dispatch('newTransaction', false)
      }
      router.push(path)
    },

    async sendToDetails () {
      const network = await this.store.getters.resolveDomainName(this.newestTransaction.network)
      router.push(`/search-transaction?network=${network}&hash=${this.newestTransaction.hash}`)
    }
  },

  watch: {
    newTransaction (newTr, oldTr) {
      if (newTr !== oldTr && newTr) {
        this.sendToDetails()
      }
    }
  },

  computed: {
    activeTab (): string {
      switch (this.$route.name) {
        case 'Bridge':
          return firstTab
        // case 'Transactions History':
        //   return secondTab
        case 'Transaction Search':
          return thirdTab
        default:
          return firstTab
      }
    }
  }
})
</script>

<style scoped>
.card {
  width: 100%;
  min-width: 400px;
  max-width: 700px;
}

@media only screen and (max-width: 600px) {
  .card {
    min-height: calc(100vh - 200px);
  }
}
</style>
