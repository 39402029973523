/**
 * Wallet Module contains most information that comes from a user's wallet
 * This is also a good module to look at for how to write a Vuex module
 */
import { MutationTree, ActionTree } from 'vuex'
import * as types from '../mutation-types'
import { networks } from '../../config/index'
import * as mmUtils from '../../utils/metamask'
import { TokenMetadata } from '../../config/config.types'

declare const window: any

// TODO: define network type better
export interface WalletState {
  connected: boolean;
  address: string;
  network: string;
  networkUnsupported: boolean | null;
  token: TokenMetadata;
}

const state: WalletState = {
  connected: false,
  address: '',
  network: '',
  networkUnsupported: null,
  token: {
    nativeNetwork: '',
    symbol: '',
    name: '',
    icon: '',
    decimals: 0,
    coinGeckoId: '',
    tokenIdentifier: {
      domain: '',
      id: ''
    },
    nativeOnly: false,
    minAmt: 0
  }
}

const mutations = <MutationTree<WalletState>> {
  [types.SET_WALLET_CONNECTION] (
    state: WalletState, connected: boolean
  ) {
    console.log('{dispatch} set wallet connection: ', connected)
    state.connected = connected
  },

  [types.SET_WALLET_ADDRESS] (
    state: WalletState, address: string
  ) {
    console.log('{dispatch} set wallet address: ', address)
    state.address = address
  },

  [types.SET_WALLET_NETWORK] (
    state: WalletState, network: string
  ) {
    console.log('{dispatch} set wallet network: ', network)
    state.network = network
  },

  [types.SET_WALLET_TOKEN] (
    state: WalletState, token: TokenMetadata
  ) {
    console.log('{dispatch} set wallet token: ', token)
    state.token = token
  },

  [types.SET_NETWORK_UNSUPPORTED] (
    state: WalletState, unsupported: boolean
  ) {
    console.log('{dispatch} set wallet token: ', unsupported)
    state.networkUnsupported = unsupported
  }
}

const actions = <ActionTree<WalletState, any>> {
  setWalletConnection ({ commit }, connected: boolean) {
    commit(types.SET_WALLET_CONNECTION, connected)
  },

  setNetworkUnsupported ({ commit }, unsupported: boolean) {
    commit(types.SET_NETWORK_UNSUPPORTED, unsupported)
  },

  async connectWallet ({ dispatch, commit }) {
    // if window.ethereum does not exist, do not connect
    const { ethereum } = window as any
    if (!ethereum) return

    await window.ethereum.request({ method: 'eth_requestAccounts' })
    const provider = await mmUtils.getMetamaskProvider()
    const signer = await provider.getSigner()

    let network
    try {
      network = await mmUtils.getNetwork(provider)
      commit(types.SET_NETWORK_UNSUPPORTED, false)
    } catch (e) {
      commit(types.SET_NETWORK_UNSUPPORTED, true)
      throw new Error('Network not supported: ' + network + ' ' + (e as Error).message)
    }
    // wait for optics otherwise it is undefined by the time the wallet tries to get the balance
    await dispatch('instantiateOptics')
    const address = await signer.getAddress()
    const token = networks[network.toLowerCase()].nativeToken

    dispatch('setWalletAddress', address)
    // Initially set recipient address to wallet address in userInput module
    dispatch('setRecipientAddress', address, { root: true })
    commit(types.SET_WALLET_NETWORK, network)
    dispatch('setToken', token)
    commit(types.SET_WALLET_CONNECTION, true)
  },

  setWalletAddress ({ commit }, address: string) {
    commit(types.SET_WALLET_ADDRESS, address)
  },

  setToken ({ commit, dispatch }, token: string) {
    commit(types.SET_WALLET_TOKEN, token)
    dispatch('getBalanceFromWallet')
  },

  async setWalletNetwork ({ dispatch, state, commit }, networkName: string) {
    if (!state.connected) {
      dispatch('connectWallet')
    }

    // if window.ethereum does not exist, do not instantiate optics
    const { ethereum } = window as any
    if (!ethereum) return

    const network = networks[networkName.toLowerCase()]
    const hexChainId = '0x' + network.chainID.toString(16)
    try {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: hexChainId }]
      })
    } catch (switchError: any) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: hexChainId,
                rpcUrls: [network.rpcUrl],
                chainName: network.name,
                nativeCurrency: {
                  name: network.nativeToken.name,
                  symbol: network.nativeToken.symbol,
                  decimals: network.nativeToken.decimals
                }
              }
            ]
          })
        } catch (addError: any) {
          // TODO: handle "add" error, alert?
          console.error(addError)
          throw addError
        }
      }
      console.error(switchError)
      throw switchError
      // TODO: handle other "switch" errors, alert?
    }

    if (!network) {
      commit(types.SET_NETWORK_UNSUPPORTED, true)
      throw new Error('Network not supported: ' + networkName)
    }
    commit(types.SET_NETWORK_UNSUPPORTED, false)

    dispatch('instantiateOptics')
    commit(types.SET_WALLET_NETWORK, network.name)
    await dispatch('instantiateOptics')
  }
}

export default {
  state,
  mutations,
  actions
}
