<template>
  <n-alert
    v-if="show"
    class="disclaimer-alert"
    closable
    :on-close="dismiss"
  >
    <template #icon>
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32"><path d="M16 20a1.5 1.5 0 1 0 1.5 1.5A1.5 1.5 0 0 0 16 20z" fill="currentColor"></path><path d="M15 9h2v9h-2z" fill="currentColor"></path><path d="M26 28H6a2.002 2.002 0 0 1-2-2V6a2.002 2.002 0 0 1 2-2h20a2.002 2.002 0 0 1 2 2v20a2.002 2.002 0 0 1-2 2zM6 6v20h20.001L26 6z" fill="currentColor"></path></svg>
    </template>
    <div class="disclaimer-alert__text">{{ disclaimer }}</div>
  </n-alert>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { NAlert } from 'naive-ui'
import { useStore } from '../../store'
import { disclaimer } from '../../i18n/main.json'

export default defineComponent({
  components: {
    NAlert
  },
  data () {
    return {
      show: this.getShow(),
      disclaimer
    }
  },
  setup () {
    const store = useStore()
    return {
      store,
      showDisclaimer: computed(() => store.state.userInput.showDisclaimer)
    }
  },
  methods: {
    getShow () {
      return this.showDisclaimer
    },

    dismiss () {
      this.store.dispatch('dismissDisclaimer')
    }
  }
})
</script>

<style scoped>
.disclaimer-alert {
  background-color: rgba(226, 64, 132, 0.6);
  border: 1px solid #E24084;
  margin-bottom: 20px;
}
.disclaimer-alert__text {
  padding-right: 20px;
}
.disclaimer-alert__text a {
  color: #e1e1e1
}
</style>
