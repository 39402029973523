import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/OpticsLong.svg'


const _hoisted_1 = { class: "nav__outer" }
const _hoisted_2 = { class: "nav" }
const _hoisted_3 = { class: "nav__container" }
const _hoisted_4 = {
  key: 1,
  class: "nav__connected"
}
const _hoisted_5 = { class: "nav__connected__data" }
const _hoisted_6 = { class: "nav__connected__address" }
const _hoisted_7 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_button = _resolveComponent("n-button")!
  const _component_n_text = _resolveComponent("n-text")!
  const _component_Wallet_Modal = _resolveComponent("Wallet-Modal")!
  const _component_Network_Alert = _resolveComponent("Network-Alert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "version" }, [
      _createElementVNode("span", null, [
        _createTextVNode("You are on "),
        _createElementVNode("a", {
          class: "version__info__link",
          href: "https://forum.celo.org/t/optics-v2-is-live/2554"
        }, "Optics v1"),
        _createTextVNode(". Return funds you've previously bridged here. ")
      ]),
      _createElementVNode("a", {
        class: "version__link",
        href: "https://www.optics.app"
      }, "Go to v2")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[3] || (_cache[3] = _createElementVNode("img", {
          height: "50",
          width: "145",
          alt: "Bridges by Optics v2",
          src: _imports_0,
          class: "nav__logo"
        }, null, -1)),
        (!_ctx.walletConnected && !_ctx.networkUnsupported && _ctx.metamaskInstalled)
          ? (_openBlock(), _createBlock(_component_n_button, {
              key: 0,
              color: "#E24084",
              onClick: _ctx.handleConnect,
              disabled: _ctx.disabled,
              class: "capitalized"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" Connect Wallet ")
              ])),
              _: 1
            }, 8, ["onClick", "disabled"]))
          : _createCommentVNode("", true),
        (_ctx.walletConnected && !_ctx.networkUnsupported)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_n_text, { class: "nav__connected__data--text capitalized" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.networkText) + _toDisplayString(_ctx.walletNetwork), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_n_text, {
                  class: "nav__connected__data--text capitalized",
                  bordered: false
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.balanceText) + _toDisplayString(_ctx.toDecimals(_ctx.tokenBalance, _ctx.walletToken.decimals, 4)) + " " + _toDisplayString(_ctx.walletToken.symbol), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_n_button, {
                  type: "primary",
                  ghost: "",
                  round: "",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (this.showAddressModal = true))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.truncateAddr(_ctx.walletAddress)), 1)
                  ]),
                  _: 1
                })
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_Wallet_Modal, {
        show: _ctx.showAddressModal,
        onHide: _cache[1] || (_cache[1] = ($event: any) => (this.showAddressModal = false))
      }, null, 8, ["show"])
    ]),
    (_ctx.networkUnsupported)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_Network_Alert)
        ]))
      : _createCommentVNode("", true)
  ]))
}