<template>
  <n-modal
    :show="show"
  >
    <n-card
      closable
      class="modal"
      @close="$emit('hide')"
    >
      <template #header>
        <div class="modal__header">
          Wallet Details
          <n-radio checked class="modal__header__connected" size="small">
            <n-text type="primary">Connected</n-text>
          </n-radio>
        </div>
      </template>

      <p class="modal__label">Address:</p>
      <Copy-Hash :value="walletAddress"/>
      <n-card class="modal__tokens">
        <h3>Token Addresses</h3>
        <div v-for="(network, i) in Object.keys(representations)" :key="i">
          <n-divider/>
          <h4 class="capitalized">{{ network }}</h4>
          <div v-for="(repr, j) in Object.keys(representations[network])" :key="j">
            <div v-if="representations[network][repr] !== zeroAddress" class="modal__token">
              {{ repr }}:
              <div class="modal__token__address">
                <Copy-Hash class="modal__token__copy" :value="representations[network][repr]" :text="true"/>
                <n-button size="tiny" class="modal__token__button" @click="addToken(network, repr, representations[network][repr])">
                  Add
                  <n-icon>
                    <add-outline/>
                  </n-icon>
                </n-button>
              </div>
            </div>
          </div>
        </div>
      </n-card>
    </n-card>
  </n-modal>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { NModal, NCard, NText, NRadio, NDivider, NButton, NIcon } from 'naive-ui'
import { AddOutline } from '@vicons/ionicons5'
import { representations, tokens, ZERO_ADDRESS } from '../config'
import { useStore } from '../store'
import CopyHash from './Transactions/CopyHash.vue'

export default defineComponent({
  components: {
    NModal,
    NCard,
    NText,
    NRadio,
    NDivider,
    NButton,
    NIcon,
    AddOutline,
    CopyHash
  },

  props: {
    show: Boolean
  },

  data () {
    return {
      representations,
      zeroAddress: ZERO_ADDRESS
    }
  },

  setup () {
    const store = useStore()

    return {
      // access a state in computed function
      walletAddress: computed(() => store.state.wallet.address),
      walletConnected: computed(() => store.state.wallet.connected),
      store
    }
  },

  methods: {
    async addToken (network: string, key: string, address: string) {
      const token = tokens[key]
      await this.store.dispatch('setWalletNetwork', network)
      try {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        const wasAdded = await (window as any).ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
              address,
              symbol: token.symbol,
              decimals: token.decimals,
              image: token.icon
            }
          }
        })

        if (wasAdded) {
          console.log('Token Added')
        } else {
          console.log('Error adding token')
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
})
</script>

<style scoped>
.modal {
  width: 90%;
  max-width: 600px;
  height: 90vh;
  overflow: scroll;
}
.modal__header {
  display: flex;
}
.modal__header__connected {
  margin-left: 10px;
  font-size: 14px !important;
}

.modal__label {
  margin-bottom: 8px;
}

.modal__tokens {
  margin-top: 20px;
}
.modal__token {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.modal__token__button {
  margin-left: 10px;
}
.modal__token__address {
  display: flex;
  max-width: calc(100% - 100px);
  margin-left: 20px;
}
.modal__token__copy {
  max-width: calc(100% - 60px);
}
</style>
